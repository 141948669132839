<template>
    <div id="dashboard" class="d-flex flex-column" style="min-height: 100vh;">
        <DashboardHeader />

        <div class="flex-grow-1 bg-white border rounded shadow-sm mx-2 p-3 font-weight-light">
            <p class="h2 font-weight-bold">O aplikaci</p>
            <p>
                This page is currently in development.
            </p>
        </div>

        <DashboardFooter />
    </div>
</template>

<script>
import DashboardHeader from './DashboardHeader.vue'
import DashboardFooter from './DashboardFooter.vue'

export default {
    name: 'DashboardAbout',
    components: {
        DashboardHeader,
        DashboardFooter
    },
}
</script>